.courseTable {
    width:100%;
}


.courseGrid {
    width:100%;
    margin-top: 10px;
    margin-bottom: 25px;
}

.heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.docsLink {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.fileSelector, .exportButton {
    width: 100%;
    text-align: center; 
}

.image {
    width: 100%;
}

.tutorialStep {
    margin-left: 5%;
}
    /* .courseTableRow > div {
        flex: 1;
    } */